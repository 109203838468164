import $ from 'jquery';

import * as M from '@materializecss/materialize';

import Notification from '@js/components/theme/notification';

// import PWAManager from './modules/pwaManager';

if (process.env.NODE_ENV === 'production') {
    require('@js/production');
} else {
    require('@js/development');
}

// Required for importing Summernote
window.$ = $;
window.jQuery = $;

// jQuery
require('jquery-ujs');

// Define settings
window.settings = JSON.parse(document.getElementById('settings')?.innerText || '{}');

// Materialize
// Imported globally
M.AutoInit();

// Initialize notifications
setTimeout(() => Notification && Notification.initialize(), 200);

// // PWA manager
// PWAManager.initialize();

// // Warn user about cookies
// if (process.env.NODE_ENV === 'production') {
//     const cookieChoices = require('./modules/cookieChoices').default;
//
//     setTimeout(() => {
//         cookieChoices.displayCookies(window.termsUrl);
//     }, 2000);
// }
