import React from 'react';

import {createRoot} from 'react-dom/client';

import {
    NotificationStack
} from 'react-notification';

import * as Utils from '@js/modules/utils';

import('@css/shared/components/_notification.scss');

const Notification = {};


class NotificationComponent extends React.Component {
    constructor(props) {
        super(props);

        this._timeout = null;

        ['success', 'alert', 'warn', 'error'].forEach((type) => {
            Notification[type] = (message, actionButton, actionCallback) => {
                this._handleAdd(type, message, actionButton, actionCallback);
            };
        });

        Notification.initialize = this._checkInitialNotification;
    }

    state = {
        notifications: []
    };

    componentWillUnmount() {
        if (this._timeout) {
            clearTimeout(this._timeout);
        }
    }

    _checkInitialNotification = () => {
        const flashes = document.querySelectorAll('.blog-flash');

        Array.prototype.forEach.call(flashes, function (element) {
            const level = element.getAttribute('data-level');
            const token = element.getAttribute('data-flash-token');

            if (sessionStorage) {
                // Do not display same flash message twice
                if (sessionStorage.getItem(`flash-message-${token}`)) {
                    return;
                }
            }

            // Let's the Notification component initialize
            document.addEventListener('DOMContentLoaded', function () {
                setTimeout(function () {
                    if (level === 'success') {
                        Notification.success(element.innerHTML);
                    } else if (level === 'error') {
                        Notification.error(element.innerHTML);
                    } else {
                        Notification.info(element.innerHTML);
                    }
                }, 200);
            });

            if (sessionStorage) {
                sessionStorage.setItem(`flash-message-${token}`, 'true');
            }
        });
    };

    _handleAdd = (level, message, actionButton, actionCallback, time = 10) => {
        const key = Utils.uuid();

        return this.setState({
            notifications: this.state.notifications.concat([{
                message: message,
                key: key,
                action: actionButton,
                className: `notification-${level}`,
                onClick: (deactivate) => {
                    if (typeof deactivate === 'function') {
                        deactivate();
                    }

                    if (typeof actionCallback === 'function') {
                        actionCallback();
                    }

                    this._timeout = setTimeout(() => this.removeNotification(key), 400);
                },
                dismissAfter: time * 1000
            }])
        });
    };

    removeNotification = (key) => {
        this.setState({
            notifications: this.state.notifications.filter((notif) => notif.key !== key)
        });
    };

    render() {
        return (
            <div className="notification">
                <NotificationStack activeBarStyleFactory={(index, style) => ({bottom: 2 + index * 4 + 'rem', ...style})}
                                   barStyleFactory={(index, style) => ({bottom: 2 + index * 4 + 'rem', ...style})}
                                   actionStyleFactory={(index, style) => ({...style})}
                                   dismissInOrder={true}
                                   dismissAfter={1000}
                                   notifications={this.state.notifications}
                                   onDismiss={(notification) => this.removeNotification(notification.key)}/>
            </div>
        );
    }
}

const root = createRoot(document.getElementById('notification-component'));
root.render(
    <NotificationComponent/>
);

export default Notification;
